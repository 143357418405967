// Styles for blocks.
// -----------------------------------------------------------------------------

.ads1, .ad-title {
	padding: 20px 0 10px 0;
}
.ads2 {
	padding: 20px 0;
}

.rek-block-0 {
	padding: 20px 0;
}
.rek-block {
	clear: both;
	padding: 10px 0;
	text-align: center;
}
.rek-block > div {
	text-align: center;
}
.toc_ads {
	margin: 10px 0;
	text-align: center;
}
.footer-ad {
	margin-top: 30px;
	margin-bottom: 10px;
}
.bottom1 {
	padding-top: 20px;
	padding-bottom: 20px;
	text-align: center;
}
.bottom2 {
	padding-bottom: 20px;
	text-align: center;
}
.bottom2 > div {
	display: inline-block;
}
.block--2 {
	margin-top: 20px;
}

.block--3 {
	min-height: 905px;
	margin-top: 40px;
	.h2 {
		padding-bottom: 5px;
		border-bottom: 5px solid #0099B9;
		font-size: 18px;
		text-transform: uppercase;
	}
}
.first-front-block {
	height: 375px;
	margin-bottom: 20px;
	background: #593e46 url('/sites/all/themes/expert/assets/images/first/temn.jpg') no-repeat center;
	@media (max-width: 600px) {
		height: 250px;
	}
	.banner {
	}
	.fbn-category {
		margin-left: 30px;
		margin-top: 50px;
		@media (max-width: 600px) {
			margin-top: 20px;
		}
		a {
			color: #fff;
			text-transform: uppercase;
			font-size: 18px;
			font-weight: bold;
			font-family: $headings-font-family;
		}
	}
	.fbn-title {
		margin-left: 30px;
		margin-top: 30px;
		width: 50%;
		@media (max-width: $screen-sm-max) {
			width: 70%;
			margin-top: 10px;
		}
		a {
			text-transform: uppercase;
			font-size: 36px;
			font-weight: bold;
			font-family: $headings-font-family;
			color: #26ddf6;
			@media (max-width: $screen-sm-max) {
				font-size: 30px;
			}
		}
	}
	.fbn-desc {
		margin-left: 30px;
		margin-top: 30px;
		color: #eee;
		width: 50%;
		@media (max-width: $screen-sm-max) {
			width: 70%;
			margin-top: 10px;
		}
		@media (max-width: 600px) {
			display: none;
		}
	}
}

.ya-search {
	padding: 20px 10px 10px 10px;
	float: right;
	width: 300px;
	@media (max-width: $screen-xs-max) {
		width: 100%;
		padding-top: 0;
		float: none;
	}
	table th, table td, tbody {
		border: none;
	}
}
#ya-site-form0 .ya-site-form__submit_type_image {
	background: url("/sites/all/themes/expert/assets/images/icon/search.png") no-repeat !important;
}

#ya-site-form0 .ya-site-form__submit_type_image {
	width: 32px !important;
	height: 32px !important;
}

#ya-site-form0 .ya-site-form__form .ya-site-form__input-text {
     padding: 5px;
}

#ya-site-results tbody,
#ya-site-results table th, 
#ya-site-results table td {
	border: none;
}

#search_form_icon {
	float: right;
	height: 49px;
	width: 49px;
	background: url("/sites/all/themes/expert/assets/images/icon/searchw.png") no-repeat center center;
	cursor: pointer;
}
#search_form {
	display: none;
	padding: 5px;
	margin-bottom: 10px;
	background-color: #00789a;
}

.moon_calendar {
	.h2	{
		padding-bottom: 5px;
		padding-top: 5px;
		height: 55px;
		padding-left: 60px;
    	border-bottom: 5px solid #ffc64f;
    	font-size: 18px;
    	text-transform: uppercase;
    	background: url("/sites/all/themes/expert/assets/images/icon/moon.png") no-repeat left center;
	}
	.moon_links {
		font-size: 18px;
		text-align: center;
	}
}