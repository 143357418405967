// Styles for Nodes
// -----------------------------------------------------------------------------

.node-link {
	height: 80px;
    padding: 10px 0;
    display: table;
	.node-link-cover {
		display: table-cell;
		vertical-align: middle;
		float: left;
	    padding-right: 10px;
	}
	.node-link-title {
		display: table-cell;
	    vertical-align: middle;
	    font-size: 1.2em;
	}
}

.node .field-body a.btn {
	text-decoration: none;
	padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 30px;
}

.link-block-1, .link-block-2 {
	.btn {
		margin-top: 30px;
		margin-bottom: 30px;
	}
}

.otziv > p > img {
	margin: 10px 0;
}

.otziv {
	position: relative;
	text-align: center;
	background-color: #fff;
	border: 5px solid #0099ba;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 20px;
	-webkit-box-shadow: 2px 2px 4px #888;
	-moz-box-shadow: 2px 2px 4px #888;
	box-shadow: 2px 2px 4px #888;
	width: 90%;
	margin-left: auto;
	margin-right: auto;
	z-index: 100;
	margin-top: 30px;
	margin-bottom: 30px;
	@media (max-width: $screen-xs) {
		width: 100%;
	}
}

.otziv:before { 
	content: ' '; 
	position: absolute; 
	width: 0; 
	height: 0; 
	left: 35px; 
	top: -44px; 
	border: 20px solid; 
	border-color: transparent transparent #0099ba transparent; 
}

.otziv:after { 
	content: ' '; 
	position: absolute; 
	width: 0; 
	height: 0; 
	left: 40px; 
	top: -30px; 
	border: 15px solid; 
	border-color: transparent transparent #fff transparent; 
}
.submitted {
	margin: 10px 0;
}
.node-date {
	font-size: 15px;
	font-weight: bold;
	text-align: right;
	color: #777;
}
.author-name {
	font-size: 16px;
	font-weight: bold;
	margin-top: 15px;
}
.author-positionn {
	color: #777;
	font-size: 12px;
}
.author-photo {
	float: left;
	margin-right: 20px;
}
.inline div {
	display: inline-block;
}
.field-author {
	color: #777;
	margin-bottom: 10px;
	a {
		color: #777;
		text-decoration: underline;
		font-style: italic;
	}
}
.node-author {
	.field-author-photo {
		float: left;
		margin-right: 10px;
		max-width: 50%;
	}
	.field-author-photo img {
		max-width: 100%;
		height: auto;
	}
	.field-author-position {
		margin-bottom: 20px;
	}
}
.subscribe-block{
	min-height: 100px;
	position: relative;
	.subscribe-text {
		font-size: 17.5px;
		line-height: 29px;
		padding: 20px 10px;
		.zen-link {
			background: url("/sites/all/themes/expert/assets/images/icon/yandexzen.png") no-repeat;
		}
		.zen-link:first-letter {
			color: #ff0000;
		}
		.zen-link:hover {
			color: #ff0000;	
		}
		.vk-link {
			background: url("/sites/all/themes/expert/assets/images/icon/vk-active.png") no-repeat;
		}
		.sub-link {
			padding-left: 32px;
			display: inline-block;
			font-weight: bold;
		}
	}
}

/* cat start */
.cat-main-image-wrapper {
    position: relative;
    float: left;
    padding-right: 20px;
}
.cat-left-eye-main-body {
    left: 51px;
    position: absolute;
    top: 28px;
    z-index: 900;
}
.cat-right-eye-main-body {
    left: 78px;
    position: absolute;
    top: 28px;
    z-index: 900;
}
.cat-left-eye-main {
    top: 35.5642px; 
    left: 63.4312px;
    position: absolute;
    z-index: 900;
}
.cat-right-eye-main {
    top: 35.637px; 
    left: 90.4009px;
    position: absolute;
    z-index: 900;
}
#mew {
	display: none;
	margin-right: 10px;
	border: 1px solid #ccc;
	border-radius: 5px;
	padding: 5px 10px;
	top: -30px;
    left: 130px;
    position: absolute;
    background-color: #fff;
}

/* cat end */