// Styles for Menus and Navs
// -----------------------------------------------------------------------------

.navbar {
	border: 0;
}

.front .navbar {
  margin-bottom: 0;
}

/*
 * @file
 * CSS for the responsive menu toggle checkbox / button.
 */
.sm-menu-btn {
  position: relative;
  display: inline-block;
  width: 28px;
  height: 28px;
  text-indent: 28px;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  margin-top: 10px;
}

/* hamburger icon */
.sm-menu-btn-icon, .sm-menu-btn-icon:before, .sm-menu-btn-icon:after {
  position: absolute;
  top: 50%;
  left: 2px;
  height: 2px;
  width: 24px;
  background: #eee;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
}
.sm-menu-btn-icon:before {
  content: '';
  top: -7px;
  left: 0;
}
.sm-menu-btn-icon:after {
  content: '';
  top: 7px;
  left: 0;
}
/* x icon */
.sm-menu-state:checked ~ .sm-menu-btn .sm-menu-btn-icon {
  height: 0;
  background: transparent;
}
.sm-menu-state:checked ~ .sm-menu-btn .sm-menu-btn-icon:before {
  top: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.sm-menu-state:checked ~ .sm-menu-btn .sm-menu-btn-icon:after {
  top: 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
/* hide menu state checkbox (keep it visible to screen readers) */
.sm-menu-state {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  overflow: hidden;
  clip: rect(1px,1px,1px,1px);
}
/* hide the menu in mobile view */
.sm-menu-state:not(:checked) ~ .sm {
  display: none;
}
.sm-menu-state:checked ~ .sm {
  display: block;
}
@media (min-width: 768px) {
  /* hide the button in desktop view */
  .sm-menu-btn {
    position: absolute;
    top: -99999px;
  }
  /* always show the menu in desktop view */
  .sm-menu-state:not(:checked) ~ .sm {
    display: block;
  }
}
.front-link-wrapper {
  display: none;
}
@media (max-width: 480px) {
  .header-wrapper {
    display: none;
  }
  .main-menu {
    background: url('/sites/all/themes/expert/assets/images/mobile-logo.png') no-repeat 40% top;
  }
  .front-link-wrapper {
    display: block;
    position: absolute;
    left: 18%;
    width: 64%;
    height: 50px;
  }
  .front-link {
    height: 50px;
  }

}