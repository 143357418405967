// Styles for Tabs, Messages, ..etc
// -----------------------------------------------------------------------------
input::-webkit-input-placeholder {
color: #777 !important;
}
 
input::-moz-placeholder {
color: #777 !important;  
}
 
input:-ms-input-placeholder {  
color: #777 !important;  
}
.breadcrumb {
	margin-bottom: 10px;
}