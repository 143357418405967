// Typography
// -----------------------------------------------------------------------------
.img-responsive {
	display: inline-block;
	height: auto !important;
	@media (max-width: $screen-xs-max) {
		display: block;
		margin: 0 auto;
	}
}
.image-medium {
	padding: 0 10px 10px 10px;
	@media (max-width: $screen-xs-max) {
		float: none !important;
	}
}

.video-filter {
	text-align: center;
	margin-bottom: 20px;
}

h1, h2, h3, h4 {
	clear: both;
	
}


blockquote {
	clear: both;
}

#footer {
	margin: 0;
	padding: 20px 0;
}

.footer-wrapper, .bottom-wrapper {
	background: $footer-bg;
}
.footer-wrapper {
	padding: 25px 0;
}
.bottom {
	border-bottom: solid 1px $line-color;
	@media (max-width: $screen-xs-max) {
		text-align: center;
		.h4 {
			margin-top: 20px;
		}
		li {
			padding: 3px 0;
			margin: 2px 0;
		}
	}
}

.top-logo {
	padding: 10px 0;
	float: left;
}


#breadcrumb a {
	color: #aaa;
	text-transform: uppercase;
}
#breadcrumb {
	color: #aaa;
}

.footer-logo {
	background: url('/sites/all/themes/expert/assets/images/footer-logo.png') no-repeat;
	height: 55px;
	width: 262px;
	margin-top: 20px;
}

.footer-sitename {
	margin-left: 118px;
	padding-top: 5px;
}

.footer-sitedesc {
	margin-left: 118px;
}

.age-big {
	font-size: 24px;
	font-weight: bold;
	color: #00789a;
	float: left;
	padding-right: 10px;
}
.age-desc {
	line-height: 16px;
}

.footer-social .social-logo a {
	width: 29px;
	height: 29px;
	margin: 0 5px;
	display: inline-block;
}

.footer-social a {
	opacity: .75;
}
.footer-social a:hover {
	opacity:1;
}
.footer-social .vk a {
	background: url('/sites/all/themes/expert/assets/images/icon/vk-active.png') no-repeat;
}
.footer-social .fb a {
	background: url('/sites/all/themes/expert/assets/images/icon/fb-active.png') no-repeat;
}
.footer-social .twitter a {
	background: url('/sites/all/themes/expert/assets/images/icon/twitter-active.png') no-repeat;
}
.footer-social .pint a {
	background: url('/sites/all/themes/expert/assets/images/icon/pint-active.png') no-repeat;
}
.footer-social .postila a {
	background: url('/sites/all/themes/expert/assets/images/icon/postila-active.png') no-repeat;
}
.footer-social .ok a {
	background: url('/sites/all/themes/expert/assets/images/icon/ok-active.png') no-repeat;
}
.footer-social .yandexzen a {
	background: url('/sites/all/themes/expert/assets/images/icon/yandexzen.png') no-repeat;
}

.footer-menu, .footer-social {
	margin-top: 20px;
}
.footer-social {
	margin-bottom: 20px;
}
.footer-menu {
	list-style-type: none;
}
.footer-menu li {
	margin: 5px 0;
}

#bottom {
	padding: 40px 0 20px 0;
}
#bottom .h4 {
	text-transform: uppercase;
	margin-bottom: 5px;
	font-weight: normal;
	font-size: 16px;
}
#bottom ul {
	list-style-type: none;
	padding-left: 0;
}

#main-container {
	margin-bottom: 30px;
}

.navbar {
	background: $navbar-bg;
	margin-bottom: 20px;
}

#toc_container {
	
	border: none;
	background: #eee;
	padding: 20px 50px;
	margin: 0 auto;
	font-size: 110%;
	min-width: 60%;
}

.page-header {
	border: none;
	margin-bottom: 10px;
}

blockquote {
	border: 3px solid #0099ba;
	margin: 20px 0;
	padding: 20px 20px 20px 100px;
	background: url('/sites/all/themes/expert/assets/images/icon/alert1.png') no-repeat 20px;
	@media (max-width: $screen-xs-max) {
		background: none;
		padding: 15px;
	}
}

.link-block-1 {
	font-size: 16px;
	border: 3px solid #fe6804;
	margin: 20px 0;
	padding: 20px 20px 20px 100px;
	background: url('/sites/all/themes/expert/assets/images/icon/alert2.png') no-repeat 20px;
	@media (max-width: $screen-xs-max) {
		background: none;
		padding: 15px;
	}
}

.link-block-2 {
	font-size: 16px;
	border: 3px solid #fe6804;
	margin: 20px 0;
	padding: 20px;
	@media (max-width: $screen-xs-max) {
		background: none;
		padding: 15px;
	}
}



.share {
	margin: 20px 0;
}
.share .h3 {
	float: left;
	margin-top: 0;
	margin-right: 20px; 
}
.view-new .views-row {
	clear: both;
	min-height: 80px;
	margin: 25px 0;
}

.field-cover {
	float:left;
	padding-right: 10px;
}

#quicktabs-new_popular .quicktabs-tabs {
	border-bottom: 6px solid #fe6204;
	height: 40px;
}
#quicktabs-new_popular .quicktabs-tabs li {
	display: inline-block;
	float: left;
	margin: 0;
	padding: 10px 20px;
	text-transform: uppercase;
	font-family: $headings-font-family;
	text-align: center;
}

#quicktabs-new_popular .quicktabs-tabs li a {
	padding: 10px 0;
}
#quicktabs-new_popular .quicktabs-tabs li.active a {
	color: #fff;
}
#quicktabs-new_popular .quicktabs-tabs li a,
#quicktabs-new_popular .quicktabs-tabs li a:hover {
	text-decoration: none;
}

#quicktabs-new_popular .quicktabs-tabs li.active {
	background: #fe6204;
}

#quicktabs-new_popular .quicktabs-tabs li.first {
	width: 40%;
}
#quicktabs-new_popular .quicktabs-tabs li.last {
	width: 60%;
}

.node .field-body > ul,
.term-desc > ul {
	padding-left: 10px;
}
.node .field-body > ul li,
.term-desc > ul li {
	list-style-type: none;
	margin-bottom: 5px;
	padding-left: 20px;
    position: relative;
}
.node .field-body > ul li:before,
.term-desc > ul li:before {
	content: "";
	padding-left: 5px;
    position: absolute;
    left: 0;
    top: 7px;
    width: 7px;
    height: 7px;
    background: #0099ba;
    border-radius: 7px;
}

.node .field-body .link-block-1 > ul {
	padding-left: 10px;
	li {
		list-style-type: none;
		margin-bottom: 5px;
		padding-left: 20px;
	    position: relative;
	    padding-top: 2px;
	    padding-bottom: 2px;
	}
	li:before {
		content: "";
		padding-left: 5px;
	    position: absolute;
	    left: 0;
	    top: 9px;
	    width: 9px;
	    height: 9px;
	    background: #fe6804;
	    border-radius: 9px;    	
	}
	ul  {
		padding-left: 10px;
		margin-top: 4px;
	}
	@media (max-width: $screen-xs-max) {
		padding-left: 0;
	}
}

.node .field-body > ol {
    list-style-type: none;
    counter-reset: num;
    padding-left: 10px;
}

.node .field-body > ol li {
    padding-left: 30px;
    position: relative;
    margin-bottom: 7px;
}

.node .field-body > ol li:before {
    content: counter(num); /* Выводим число */
    counter-increment: num; /* Увеличиваем значение счётчика */
	color: #fff;
	font-family: $headings-font-family;
    left: 0;
	background: #0099ba;
	width: 20px;
	padding: 0;
    font-weight: bold;
    text-align: center;
    position: absolute;
}

.toc_number {
	color: $link-hover-color;
	font-family: $headings-font-family;
}
/*
strong {
	font-family: $headings-font-family;
	font-weight: 600;
}*/
.node .field-body a {
	text-decoration: underline;
}
@media (max-width: 480px) {
	h1 {
		font-size: 32px;
	}
}