// Styles for Views
// -----------------------------------------------------------------------------

.masonry-processed {
	margin-right: auto;
	margin-left: auto; 
}
.grid-sizer {
	width: 360px;
}
.masonry-item {
	width: 360px;
	height: 360px;
	margin: 5px;
	overflow: hidden;
	padding: 0;
	border: 1px solid #ccc;
	background: transparent;
	@media (max-width: $screen-md-max) {
		width: 300px;
		height: 300px;
	}
	@media (max-width: $screen-sm-max) {
		width: 220px;
		height: 220px;
	}
	@media (max-width: $screen-xs-max) {
		width: 48%;
		max-width: 300px;
	}
	@media (max-width: $screen-xs) {
		width: 98%;
		max-width: 360px;
		height: 255px;
		margin-left: auto;
		margin-right: auto;
	}

}
.view-frontpage .masonry-item {
	.views-field-title {
		padding: 10px;
		height: 50px;
		text-transform: uppercase;
		font-family: $headings-font-family;
		font-weight: normal;
		font-size: 130%;
		a {
			color: #333;
		}
		@media (max-width: $screen-sm-max) {
			padding-top: 5px;
			font-size: 90%;
		}
	}
	.views-field-term-node-tid {
		text-transform: uppercase;
		padding-top: 10px;
		padding-left: 10px;
		font-size: 110%;
		font-family: $headings-font-family;
		@media (max-width: $screen-sm-max) {
			padding-top: 5px;
			font-size: 100%;
		}
		a {
			color: $hover-color;
		}
	}
}



.view-frontpage .views-row-2,
.view-frontpage .views-row-6,
.view-frontpage .views-row-12 {
	width: 735px;
	.views-field-title {
		padding: 20px 10px;
		position: absolute;
		top: 70%;
		height: 30%;
		width: 100%;
		font-size: 150%;
		background: rgba(0, 0, 0, .35);
		.field-content {
			display: block;
			width: 70%;
			@media (max-width: $screen-xs-max) {
				width: 100%;
			}
		}
		@media (max-width: $screen-xs-max) {
			position: relative;
			top: 0;
			background: transparent;
			padding-top: 5px;
			font-size: 100%;
		}
		a {
			color: #fff;
			@media (max-width: $screen-xs-max) {
				color: #000;
			}
		}
	}
	.views-field-term-node-tid {
		position: absolute;
		top: 60%;
		font-size: 120%;
		a {
			color: #26ddf6;
		}
		@media (max-width: $screen-xs-max) {
			position: relative;
			top: 0;
			font-size: 100%;
			a {
				color: $hover-color;
			}
		}
	}
	@media (max-width: $screen-md-max) {
		width: 615px;
	}
	@media (max-width: $screen-sm-max) {
		width: 455px;
		font-size: 90%;
	}
	@media (max-width: $screen-xs-max) {
		width: 48%;
		max-width: 300px;
	}
	@media (max-width: $screen-xs) {
		width: 98%;
		max-width: 360px;
		height: 255px;
		margin-left: auto;
		margin-right: auto;
	}
}

.masonry-item h2 {
	font-size: 1.2em; 
}

.view-frontpage .masonry-item .field-cover {
	padding-right: 0;
	float: none;

}
.view-similar .views-row {
	margin-bottom: 20px;
	@media (max-width: 420px) {
		width: 100%;
	}

}

.view-similar .views-row .row-content {
	border: 1px solid $view-border-color;
	height: 220px;
	@media (max-width: $screen-sm-max) {
			height: 250px;
	}
}

.view-similar .views-row .row-content:hover {
	text-decoration: underline;
}

.view-similar .views-row .row-content:hover a {
	color: $hover-color;
}


.view-similar .field-cover {
	float: none;
    padding: 0;
}
.view-similar .field-title {
	padding: 10px 5px;
}
.view-similar .field-title a {
	color: #333;
	font-family: $headings-font-family;
	font-weight: normal;
}

.view-taxonomy-category .views-row {
	height: 80px;
	padding: 10px 0;
	display: table;
	.field-cover {
		display: table-cell;
		vertical-align: middle;
	}
	.views-field-title {
		display: table-cell;
		vertical-align: middle;
		font-size: 1.2em;
	}
}

.category-main ul li {
	list-style-type: none;
}

ul.level-1 {
	margin-bottom: 20px;
}

ul.level-1 > li > .category-title {
	font-weight: bold;
	font-size: 1.1em;
}

.view-blog-list {
	.views-row {
		height: 80px;
		padding: 10px 0;
		display: table;
		.field-blog-cover {
			display: table-cell;
			vertical-align: middle;
		}
		.views-field-name {
			display: table-cell;
			vertical-align: middle;
			font-size: 1.2em;
		}
	}
}

.view-taxonomy-blog {
	.taxonomy-term .field-blog-cover {
		float: left;
		margin-right: 20px;
	}
	.taxonomy-term-description {
		padding-top: 10px;
	}
}
.view-taxonomy-blog > .view-content {
	border-top: 1px solid #ccc;
	clear: both;
}

.view-journal,
.view-blog,
.view-taxonomy-blog {
	.views-row {
		min-height: 200px;
    	clear: both;
    	margin-bottom: 30px;
	}
	.field-cover {
		float: left;
		width: 45%;
		padding-bottom: 5px;
		@media (max-width: $screen-xs) {
			width: 100%;
			float: none;
		}
	}
	.views-field-title a {
		color: #222;
	}
	.view-header {
		margin-bottom: 15px;
	}
}


.view-news {
	.views-row {
		min-height: 200px;
    	clear: both;
    	margin-bottom: 30px;
	}
	.field-cover {
		float: left;
		width: 45%;
		padding-bottom: 5px;
		@media (max-width: $screen-xs) {
			width: 100%;
			float: none;
		}
	}
	.views-field-title a {
		color: #222;
	}
	.view-header {
		margin-bottom: 15px;
	}
}

.view-authors {
	.views-row {
		min-height: 300px;
		clear: both;
		margin-bottom: 20px;
	}
	.field-author-photo {
		float: left;
		margin-right: 20px;
	}
	.views-field-title {
		font-size: 20px;
		font-weight: bold;
	}
	.field-body {
		margin-top: 20px;
	}
}